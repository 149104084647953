@import './color.scss';
.ttPrimary {
  background-color: $Orchid !important;
  color: $White;
  &:hover {
    background-color: rgba($Orchid, 0.8) !important;
  }
  &:disabled {
    background-color: rgba($Orchid, 0.2) !important;
  }
}
.ttSecondary {
  background-color: transparent !important;
  border: 1px solid $Navy !important;
  color: $Navy !important;
  &:hover {
    background-color: $Navy !important;
    color: $White !important;
  }
  &:disabled {
    background-color: rgba($Navy, 0.5) !important;
    color: $White !important;
  }
}
.ttTertiary {
  background-color: rgba($White, 0.2) !important;
  color: $White;
  &:hover{
    background-color: rgba($White, 0.5) !important;
  }
}
.ttGreen {
  background-color: $LightGreen !important;
  color: $White;
  &:hover{
    background-color: rgba($LightGreen, 0.8) !important;
  }
}
.ttNavy {
  background-color: $Navy !important;
  color: $White;
  &:hover {
    background-color: rgba($Navy, 0.8) !important;
  }
  &:disabled {
    background-color: rgba($Navy, 0.2) !important;
  }
}
.ttBlue {
  background-color: $Blue !important;
  color: $White;
  &:hover {
    background-color: rgba($Blue, 0.8) !important;
  }
  &:disabled {
    background-color: rgba($Blue, 0.2) !important;
  }
}

@font-face {
  font-family: "Mont";
  src: local("Mont"), url("./assets/Mont/Mont-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Mont";
  src: local("Mont"), url("./assets/Mont/Mont-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Mont";
  src: local("Mont"), url("./assets/Mont/Mont-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Mont";
  src: local("Mont"), url("./assets/Mont/Mont-Black.ttf") format("truetype");
  font-weight: 800;
}

body {
  width: 100%;
  margin: 0;
  font-family: "Mont";
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (min-width: 1200px) {
  .container {
    max-width: 94rem !important;
    padding: 0 4em !important;
  }
}

button,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  font-family: "Mont", Arial, Helvetica, sans-serif;
}

a {
  font-family: "Mont", Arial, Helvetica, sans-serif;
}

.button {
  background: #cc7191;
  padding: 0.75em 1.5em;
  border: none;
  outline: none;
  border-radius: 1.25em !important;
  color: #fff;
  font-weight: 600;
  font-family: "Mont", Arial, Helvetica, sans-serif;
}

.button.button-xl {
  padding: 1em 1.5em;
  width: 100%;
  max-width: 18rem;
}

p,
span,
input,
li {
  font-family: "Mont", Arial, Helvetica, sans-serif;
}

.text-error {
  font-size: 0.75rem;
  color: red;
  font-family: "Mont", Arial, Helvetica, sans-serif;
  font-weight: 600;
}

.text-error.normal {
  font-size: 1rem;
}

.edit {
  display: inline-block;
  cursor: pointer;
  margin-left: 0.5rem;
  margin-top: -0.5rem;
}

.text-success {
  font-weight: 600;
  color: #28a745;
}

.text-success.small {
  font-size: 0.75rem;
}

.text-no-found {
  font-weight: 700;
  font-family: "Mont", Arial, Helvetica, sans-serif;
  text-align: center;
}

.text-error.signup-error {
  margin-top: -0.75rem;
  display: block;
  margin-bottom: 0.75rem;
}

.loading-animation {
  animation: loading 1s infinite;
}

.svg-loading {
  font-size: 1.75rem;
  margin-top: -0.25rem;
}

.height-full {
  height: 100% !important;
}

.width-full {
  width: 100% !important;
}

.max-width-none {
  max-width: unset !important;
}

@keyframes loading {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.banner__tooltip {
  font-size: 1rem;
  background-color: rgb(38, 23, 77);
  z-index: 2;
  border-radius: 10px;
  padding: 20px;
  max-width: 80%;
  color: white;
}

.suspense__loading {
  text-align: center;
  min-height: calc(100vh - 17rem);
}

.hover__card,
.hover__card__inverse .hover__btn {
  cursor: pointer;
}

.hover__btn:hover {
  opacity: 0.6 !important;
}

.hover__btn_orchid:hover {
  color: $Orchid;
}

// .hover__card:hover {
//   box-shadow: 0 0 20px rgb(20 38 105 / 70%) !important;
// }

.hover__card__inverse:hover {
  box-shadow: 0 0 12px #caf1e6e6 !important;
}

.btn__tooltip {
  background-color: white;
  text-align: center;
  color: black;
  width: 20rem;
  height: auto;
  padding: 1rem;
  border-radius: 2rem;
  box-shadow: 1px 1px 7px 4px #2222;
}


.public-DraftEditorPlaceholder-inner {
  position: absolute;
  color: #BDBDBD;
}

.cursor-pointer {
  cursor: pointer;
}